$(document).ready(function () {


  // dropdown open and hide when click outside
  const
    links = '.js-dropdown-link',
    innerElements = '.js-dropdown';

  $(document).on('click', links, function (e) {
    if ($(this).hasClass('active')) {
      $(links).filter('a[href="' + this.hash + '"]').removeClass('active');
      $(innerElements).filter(this.hash).removeClass('open').attr('aria-expanded', 'false');
    } else {
      $(links).removeClass('active').filter('a[href="' + this.hash + '"]').addClass('active');
      $(innerElements).removeClass('open').attr('aria-expanded', 'false').filter(this.hash).addClass('open').attr('aria-expanded', 'true');
    }
    e.preventDefault();
  });

  // dropdown close
  const dropdownCloseFunction = function name() {
    $(links).removeClass('active');
    $(innerElements).removeClass('open').attr('aria-expanded', 'false');
  };

  $(document).on('click touchstart', function (e) {
    if ((!$(links).add(innerElements).is(e.target) && $(links).add(innerElements).has(e.target).length === 0)) {
      dropdownCloseFunction();
    }
  });

  $(document).on('keyup', function (e) {
    if (e.keyCode === 27) {
      dropdownCloseFunction();
    }
  });


  // header fixed
  $(window).on('resize', function () {
    $('.js-body > *:first-child').css('padding-top', $('.js-header').outerHeight(true) || 0);
  }).trigger('resize');


  // info section bg position
  if ($('.js-info-bg').length) {
    $(window).on('resize', function () {
      const heights = $('.js-info-list-image').map(function () {
        return $(this).height();
      }).get();

      const maxHeight = Math.max.apply(null, heights);

      $('.js-info-bg').css({ top: maxHeight });
    }).trigger('resize');
  }


  // custom select
  if ($.fn.select2) {
    $('.js-select').select2({
      minimumResultsForSearch: Infinity
    });
  }


});
